import { Component, Mixins } from 'vue-property-decorator';
import template from './ForgotPassword.Template.vue';
import ForgotPasswordValidator from './ForgotPassword.Validator';
import { Utilisateur } from '@/models';
import { UtilisateurApiHelper } from '../../../services/UtilisateurApiHelper';

@Component({
    ...template,
    name: 'ForgotPassword',
})
export default class ForgotPassword extends Mixins(ForgotPasswordValidator) {
    public $refs!: {
        form: HTMLFormElement,
    };

    public email: string = '';
    public loading: boolean = false;

    public submit() {
        if (this.$refs.form.validate()) {
            this.loading = true;
            UtilisateurApiHelper.forgotPassword({ email: this.email })
                .then((result: Utilisateur) => { if (result) { (this.$router as any).push({ name: 'login' }); } })
                .finally(() => this.loading = false);
        }
    }

    public back(): void {
        this.$refs.form.reset();
        this.$router.go(-1);
    }
}
