import { ValidationMixin } from '@/shared/mixins';
import { Component } from 'vue-property-decorator';

@Component
export default class ForgotPasswordValidator extends ValidationMixin {
    private emailRules = [
        (v: string | any) => this.required(v) || 'Le mail de confirmation est obligatoire',
        (v: string | any) => this.isEmail(v) || 'Le mail de confirmation n\'est pas valide',
    ];
}
